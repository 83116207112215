import React from 'react';
import { NavLink } from 'cccisd-react-router';
import NavBar from './NavBar';
import logo from './header-logo.png';
import style from './style.css';

export const ExternalLink = ({ to, children, className }) => {
    return (
        <li className={className}>
            <a href={to}>{children}</a>
        </li>
    );
};

const Header = () => {
    const pathArray = window.parent.location.pathname.split('/').filter(Boolean);
    const endpoint = pathArray.length > 0 ? pathArray[pathArray.length - 1] : '';

    const wordpressUrl = 'https://guhaven.org';

    return (
        <div>
            <NavBar
                className={style.navbar}
                logo={<img src={logo} className={style.headerLogo} alt="Haven Logo" />}
                logoLinkTo={wordpressUrl}
            >
                <ExternalLink to={wordpressUrl} className={[''].includes(endpoint) ? style.active : ''}>
                    Home
                </ExternalLink>
                <ExternalLink to={`${wordpressUrl}/about`} className={['about'].includes(endpoint) ? style.active : ''}>
                    About
                </ExternalLink>
                <li key="legosAdmin" className="dropdown">
                    <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Resources <span className="caret" />
                    </a>
                    <ul className="dropdown-menu">
                        <li>
                            <NavLink to="/all-resources" className={endpoint === 'all-resources' ? style.active : ''}>
                                All Resources
                            </NavLink>
                        </li>
                        <ExternalLink
                            to={`${wordpressUrl}/for-youth`}
                            className={['for-youth'].includes(endpoint) ? style.active : ''}
                        >
                            For Youth
                        </ExternalLink>
                        <ExternalLink
                            to={`${wordpressUrl}/for-parents`}
                            className={['for-parents'].includes(endpoint) ? style.active : ''}
                        >
                            For Parents
                        </ExternalLink>
                        <ExternalLink
                            to={`${wordpressUrl}/for-educators`}
                            className={['for-educators'].includes(endpoint) ? style.active : ''}
                        >
                            For Educators
                        </ExternalLink>
                        <ExternalLink
                            to={`${wordpressUrl}/for-clinicians`}
                            className={['for-clinicians'].includes(endpoint) ? style.active : ''}
                        >
                            For Clinicians
                        </ExternalLink>
                    </ul>
                </li>
                <ExternalLink
                    to={`${wordpressUrl}/get-help`}
                    className={['get-help'].includes(endpoint) ? style.active : ''}
                >
                    Get Help
                </ExternalLink>
            </NavBar>
        </div>
    );
};

export default Header;
